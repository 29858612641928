import React from 'react'
import Layout from '../components/Layout'
import NotFound from '../components/notFound'
const NotFoundPage = () => (
  <Layout headerblack="black">
    <div class="not_found_div">
      <NotFound
      heading="PAGE NOT FOUND"
      message="You just hit a route that doesn&#39;t exist...."
      link="/"
      btnmsg="Go to home page"
      />
      {/* <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p> */}
    </div>
  </Layout>
)

export default NotFoundPage
